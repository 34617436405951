import useAdminAuth from "../hooks/zustand/admin/useAdminAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";

export default function AdminGuard({ children }) {
  const { auth, admin } = useAdminAuth(
    (state) => ({ auth: state.auth, admin: state.admin }),
    shallow
  );
  const personalInformation = useAdminAuth(
    (state) => ({ admin: state.admin, getMe: state.getMe }),
    shallow
  );
  const navigate = useNavigate();

  async function getPersonalInformation() {
    await personalInformation.getMe();
    return;
  }

  useEffect(() => {
    let isMounted = true;
    if (auth.accessToken && auth.role?.replace(/["']/g, "") === "user" || auth.accessToken && auth.role?.replace(/["']/g, "") === "anonymous") {
      navigate("/dashboard/email");
    }
    if (auth.accessToken !== null && admin?._id === "") {
      getPersonalInformation().then(() => {
        if (!isMounted) return;
      });
    }
    if (!auth.accessToken) {
      navigate("/sign-in-admin");
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.accessToken, auth.role, admin?._id]);

  return <div>{children}</div>;
}
