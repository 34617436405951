import React, { useRef, useEffect, useState } from "react";
import Card from "../../components/dashboard/card/Card";
import CardHeader from "../../components/dashboard/card/Card.header";
import Paragraph from "../../components/dashboard/typography/Paragraph";
import BadgeActive from "../../components/dashboard/badge/badge.active";
import BadgeSuspend from "../../components/dashboard/badge/badge.suspend";
import Heading from "../../components/dashboard/typography/Heading";
import Button from "../../components/dashboard/button/Button";
// import CryptoPerformance from '../../components/dashboard/card/Card.crypto.performance';
// import { ReactComponent as Polkadot } from '../../components/dashboard/icon/polkadot-logo.svg';
// import { ReactComponent as Huobi } from '../../components/dashboard/icon/huobi-logo.svg';
// import { ReactComponent as Ethereum } from '../../components/dashboard/icon/ethereum-logo.svg';
// import { ReactComponent as Bitcoin } from '../../components/dashboard/icon/bitcoin-logo.svg';
// import { ReactComponent as Ripple } from '../../components/dashboard/icon/xrp-logo.svg';
import { Link } from "react-router-dom";
import usePersonalInformation from "../../hooks/zustand/usePersonalInformation";
import toRinggit from "../../lib/currencyFormatter";
import TradingViewWidget from "./Coin/TradingView";
import TodayTrade from "../../components/dashboard/card/Card.today-trade";
import Spinner from "../../components/dashboard/fallback/Spinner";
import ModalDepositUser from "./Modal/Modal.deposit";
import useLocalStorage from "../../hooks/useLocalStorage";
//import TabNavigation from "../../components/dashboard/tabs/Tabs-navigation";
import TabNavigationSmall from "../../components/dashboard/tabs/Tabs-navigation-small";
import { useTranslation } from 'react-i18next';

// const InvestmentList = [
//   {
//     label: 'Bitcoin',
//     icon: (
//       <Bitcoin
//         width={32}
//         height={32}
//         style={{
//           backgroundColor: 'white',
//           padding: '2px',
//           borderRadius: '5px',
//         }}
//       />
//     ),
//     path: 'bitcoin',
//   },
//   {
//     label: 'Ethereum',
//     icon: (
//       <Ethereum
//         width={32}
//         height={32}
//         style={{
//           backgroundColor: 'white',
//           padding: '2px',
//           borderRadius: '5px',
//         }}
//       />
//     ),
//     path: 'ethereum',
//   },
//   {
//     label: 'Polkadot',
//     icon: (
//       <Polkadot
//         width={32}
//         height={32}
//         style={{
//           backgroundColor: 'white',
//           padding: '2px',
//           borderRadius: '5px',
//         }}
//       />
//     ),
//     path: 'polkadot',
//   },
//   {
//     label: 'Houbitoken',
//     icon: (
//       <Huobi
//         width={32}
//         height={32}
//         style={{
//           backgroundColor: 'white',
//           padding: '2px',
//           borderRadius: '5px',
//         }}
//       />
//     ),
//     path: 'houbitoken',
//   },
//   {
//     label: 'Ripple',
//     icon: (
//       <Ripple
//         width={32}
//         height={32}
//         style={{
//           backgroundColor: 'white',
//           padding: '2px',
//           borderRadius: '5px',
//         }}
//       />
//     ),
//     path: 'ripple',
//   },
// ];

const TabList = ["Crypto", "Share Index"];

export default function Dashboard() {
  const userInfo = usePersonalInformation();
  const [open, setOpen] = useState(false);
  const [attempt, setAttempt] = useLocalStorage("withdrawalAttempt", 0);
  const [status, setStatus] = useState(userInfo?.user.status || "active");
  const { t, i18n } = useTranslation();
  const [tabSelected, setTabSelected] = useState({
    currentTab: 0,
    noTab: TabList.length,
  });

  const maxSuspendAttempts = userInfo?.user?.maxSuspendAttempts ?? process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;

  useEffect(() => {
    const controller = new AbortController();
    async function initialize() {
      await userInfo.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();

    //console.log("language ", i18n.language);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    async function initialize() {
      await userInfo.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user._id]);

  useEffect(() => {
    if (userInfo?.user?.status === "online" || userInfo?.user?.status === "active") {
      //setAttempt(0);
      const attemptPayload = {
        suspendedAttempts: 0,
        userStatus : "online"
      };
      const res = userInfo.updateUserSuspendedAttempts(attemptPayload);
    }

    if (
      userInfo?.user.status === "suspend" &&
      userInfo?.user.suspendedAttempts >= maxSuspendAttempts
    ) {
      setStatus("suspend");
    } else {
      setStatus(
        userInfo?.user.status !== "suspend" ? userInfo?.user.status : "active"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user.status]);

  const BTCReff = useRef(null);
  const ETHReff = useRef(null);
  const DOTReff = useRef(null);
  const HTReff = useRef(null);
  const XRPReff = useRef(null);
  const DOGEReff = useRef(null);
  const BNBReff = useRef(null);
  const TRXReff = useRef(null);
  const SOLReff = useRef(null);
  const USDTReff = useRef(null);

  const SPXReff = useRef(null);
  const NDQReff = useRef(null);
  const DJIReff = useRef(null);
  const VIXReff = useRef(null);
  const DXYReff = useRef(null);
  const DAXReff = useRef(null);
  const HSIReff = useRef(null);
  const CAC40Reff = useRef(null);
  const NIKKEIReff = useRef(null);
  const NASDAQReff = useRef(null);

  const EXYReff = useRef(null);
  const DXYForexReff = useRef(null);
  const BXYReff = useRef(null);
  const SXYReff = useRef(null);
  const JXYReff = useRef(null);
  const CXYReff = useRef(null);
  const AXYReff = useRef(null);
  const ZXYReff = useRef(null);

  return (
    <div>
      <Paragraph
        size="large"
        color="white"
        className="font-semibold flex items-center space-x-2"
      >
      
        { t('member.dashboard.Dashboard')} - {status}
      </Paragraph>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 h-full lg:h-card-height gap-5 py-4 lg:py-0">
        <Card className="flex justify-between flex-col space-y-6">
          {/* TOP CARD */}
          <CardHeader
            leftTitle={t("member.dashboard.My Profit")}
            rightTitle={userInfo.user.firstName + ' ' + userInfo.user.lastName}
            rightTitleIcon={
              status === "online" || status === "active" ? (
                <BadgeActive />
              ) : status === "suspend" ? (
                <BadgeSuspend />
              ) : (
                <Spinner />
              )
            }
            status={status}
          />
          {/* MIDDLE CARD */}
          <div className='w-full h-max'>
            <Paragraph size='xs' className='capitalize'>
              {t("member.dashboard.Investment Wallet")}
            </Paragraph>
            <Heading color="white" className="font-semibold" size="h3">
              {
                // userInfo?.user.wallet ?
                //   toRinggit(
                //     userInfo?.user.wallet ?? 0
                //   )
                //   :
                status !== "missinginfo"
                  ? toRinggit(
                      userInfo?.user.deposit +
                        (userInfo?.user?.profitLoss ?? 0) -
                        (userInfo?.user?.withdrawal ?? 0)
                    )
                  : "N/A"
              }
            </Heading>
            <div className="w-full grid grid-cols-2">
              <div>
                <Paragraph size='xs' className='capitalize'>
                  { t("member.dashboard.Deposit")}
                </Paragraph>
                <Heading color="white" className="font-semibold" size="h4">
                  {status !== "missinginfo"
                    ? toRinggit(userInfo?.user.deposit ?? 0)
                    : "N/A"}
                </Heading>
              </div>
              <div>
                <Paragraph size='xs' className='capitalize'>
                  {t("member.dashboard.Profit/(Loss)")}
                </Paragraph>
                <Heading color="white" className="font-semibold" size="h4">
                  {status !== "missinginfo"
                    ? toRinggit(userInfo?.user?.profitLoss ?? 0)
                    : "N/A"}
                </Heading>
              </div>
              <div>
                <Paragraph size='xs' className='capitalize'>
                  { t("member.dashboard.Total Withdrawal")}
                </Paragraph>
                <Heading color="white" className="font-semibold" size="h4">
                  {status !== "missinginfo"
                    ? toRinggit(userInfo?.user?.withdrawal ?? 0)
                    : "N/A"}
                </Heading>
              </div>
            </div>
            <div className="w-full bg-secondary px-3 py-5 mt-3 rounded-lg">
              <Button
                radius="full"
                className="py-2 text-white text-sm font-semibold"
              >
                {t("member.dashboard.Profit/(Loss)")}
              </Button>
            </div>
          </div>
          {/* BOTTOM CARD */}
          <div className="w-full h-max grid grid-cols-1 lg:grid-cols-2 gap-5 py-1 px-3">
            <Button
              radius="full"
              className="py-3 text-white text-base font-semibold"
              backgroundColor="secondary"
              onClick={() => setOpen(true)}
            >
              {t("member.dashboard.Deposit")}
            </Button>
            <Link to="/dashboard/withdraw">
              <Button
                radius="full"
                className="py-3 text-white text-base font-semibold"
                backgroundColor="secondary"
              >
                {t("member.dashboard.Withdrawal")}
              </Button>
            </Link>
          </div>
        </Card>
        <Card backgroundColor="secondary" className="!h-75vh">
          {/* TOP CARD */}
          <CardHeader leftTitle={t("member.dashboard.Today Trade")} />
          <div className="h-5/6  overflow-y-auto">
          {(status !== 'missinginfo') ?
            userInfo?.todayTrade?.length > 0 && userInfo?.todayTrade?.map((a, b) => {
              return (
                
                <Link key={b} to={`/dashboard/profile-detail/${a._id}`}>
                  <TodayTrade
                    member={userInfo?.user?.phoneNumber}
                    logo={a.product.coinId}
                    profile={`${a.product.coinId} ${a.profileDesc}`}
                    // profitLoss={a.profitLossAmount + a.investAmount}
                    investAmount={a.investAmount}
                    profitLoss={a.profitLossAmount}
                    changingTime={a.profitChangingTime}
                    percentage={(
                      (a.profitLossAmount / a.investAmount) *
                      100
                    ).toFixed(1)}
                    isProfit={a.isProfit}
                    isPlay={a.isPlay}
                    playDate={a.playDate}
                    finishPlayDate={a.finishPlayDate}
                  />
                </Link>
                
              );
            }) : null}
            </div>
        </Card>
        <Card backgroundColor="secondary" className="!h-75vh" >
          {/* TOP CARD */}
          <CardHeader leftTitle={t("member.dashboard.Cryptocurrency Performance")} />
          {/* MIDDLE CARD */}
          <section
            className="max-w-full h-80vh"
            aria-multiselectable={false}
          >
            <div>
              <ul className="flex items-center w-full" role="tablist">
                {TabList.map((value, index) => {
                  return (
                    <TabNavigationSmall
                      key={index}
                      tabSelected={tabSelected}
                      setTabSelected={setTabSelected}
                      tabNum={index}
                      label={t(`member.menu.${value}`)}
                    />
                  );
                })}
              </ul>
            </div>
            <div className=" grid h-2/3 overflow-y-auto ">
              {tabSelected.currentTab === 0 && (
                <div className="w-full">
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/bitcoin"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:BTCUSD"
                      containerRef={BTCReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/ethereum"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:ETHUSD"
                      containerRef={ETHReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/polkadot"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:DOTUSD"
                      containerRef={DOTReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/houbitoken"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="CRYPTO:HTUSD"
                      containerRef={HTReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/ripple"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:XRPUSD"
                      containerRef={XRPReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/dogecoin"
                      className="absolute w-full h-full z-infinity"
                    ></Link> 
                    <TradingViewWidget
                      symbol="BINANCE:DOGEUSD"
                      containerRef={DOGEReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/binance"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="BINANCE:BNBUSD"
                      containerRef={BNBReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/tron"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="BINANCE:TRXUSD"
                      containerRef={TRXReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/solana"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="BINANCE:SOLUSD"
                      containerRef={SOLReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/usdt"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="GEMINI:USDTUSD"
                      containerRef={USDTReff}
                    />
                  </div>
                </div>
              )}
              {tabSelected.currentTab === 1 && (
                <div className="w-full">
                  <div className="w-full  relative">
                    <Link
                      to='/dashboard/sp500'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="FRED:SP500"
                      containerRef={SPXReff}
                    />
                  </div>
                  {/* <div className="w-full  relative">
                    <Link
                      to='/dashboard/us100'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="TVC:NDQ"
                      containerRef={NDQReff}
                    />
                  </div> */}
                  {/* <div className="w-full  relative">
                    <Link
                      to='/dashboard/dowjones'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget 
                      symbol="DJ:DJI" 
                      containerRef={DJIReff} 
                      />
                  </div> */}
                  <div className="w-full  relative">
                    <Link
                      to='/dashboard/usdollarindex'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="CAPITALCOM:DXY"
                      containerRef={DXYReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to='/dashboard/volatility'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="CAPITALCOM:VIX"
                      containerRef={VIXReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to='/dashboard/dax'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="XETR:DAX"
                      containerRef={DAXReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to='/dashboard/hsi'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="HSI:HSI"
                      containerRef={HSIReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to='/dashboard/cac40'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="INDEX:CAC40"
                      containerRef={CAC40Reff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to='/dashboard/nikkei'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="FRED:NIKKEI225"
                      containerRef={NIKKEIReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to='/dashboard/nasdaq'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="NASDAQ:NDX"
                      containerRef={NASDAQReff}
                    />
                  </div>
                </div>
              )}
              {/* {tabSelected.currentTab === 2 && (
                 <div className="w-full">
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/dxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="CAPITALCOM:DXY"
                     containerRef={DXYForexReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/exy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:EXY"
                     containerRef={EXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/bxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:BXY"
                     containerRef={BXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/sxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:SXY"
                     containerRef={SXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/jxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:JXY"
                     containerRef={JXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/cxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:CXY"
                     containerRef={CXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/axy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:AXY"
                     containerRef={AXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/zxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:ZXY"
                     containerRef={ZXYReff}
                   />
                 </div>
               </div>
              )} */}
            </div>
          </section>
        </Card>
      </div>
      <ModalDepositUser open={open} close={() => setOpen(false)} />
    </div>
  );
}

// eslint-disable-next-line no-lone-blocks
{
  /* {InvestmentList.map((el, i) => {
  return (
    <CryptoPerformance
      label={el.label}
      path={el.path}
      price={'RM32,000.00'}
      icon={el.icon}
      percentage={'0.00335%'}
      key={i}
    />
  );
})} */
}
