import useAuth from '../hooks/zustand/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

export default function NotAuthGuard({ children }) {
  const { auth } = useAuth((state) => ({ auth: state.auth }), shallow);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.accessToken) {
      if (auth.role && (auth.role?.replace(/["']/g, '') === 'user' || auth.role?.replace(/["']/g, '') === 'anonymous')) {
        navigate('/dashboard');
      }
 
      if (auth.role && !(auth.role?.replace(/["']/g, '') === 'user' || auth.role?.replace(/["']/g, '') === 'anonymous')) {
        navigate('/en/dashboard/admin/user-list');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  return <div>{children}</div>;
}
